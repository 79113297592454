import isBrowser from "./isBrowser";

export const restoreFromSessionStorage = (sessionStorageKey) => (isBrowser()) ? JSON.parse(sessionStorage?.getItem(sessionStorageKey) ?? '{}') : {};

export const setCreditId = () => {
    let flujo = restoreFromSessionStorage('flujo')

    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('creditApplicationId') && urlParams.get('creditApplicationId') !== flujo.creditApplicationId) {
        const id = urlParams.get('creditApplicationId')
        flujo = {
            ...flujo,
            creditApplicationId: id,
        }
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    return flujo
}

export const redirectUrl=()=>{
    let urlRedirects=[
        {  url_origin:"/como-funciona.html",destino:"https://home.apoyofin.com/?page_id=43" },
        {  url_origin:"/acerca.html",destino:"https://home.apoyofin.com/?page_id=40" },
        {  url_origin:"/trabaja-con-nosotros/Beneficios.html",destino:"https://home.apoyofin.com/?page_id=846" },
        {  url_origin:"/beneficios.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/clientes.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/contacto.html",destino:"https://home.apoyofin.com/?page_id=52" },
        {  url_origin:"/dudas.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/inversionistas.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/mapa-detalle.html",destino:"https://home.apoyofin.com/?page_id=43" },
        {  url_origin:"/prestamos-personales.html",destino:"https://home.apoyofin.com/?page_id=510" },
        {  url_origin:"/privacidad.html",destino:"https://home.apoyofin.com/?page_id=856" },
        {  url_origin:"/sucursales.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/terminos.html",destino:"https://home.apoyofin.com/?page_id=864" },
        {  url_origin:"/termsAppMobile.html",destino:"https://home.apoyofin.com/?page_id=46" },
        {  url_origin:"/solicita-tu-credito/index.html",destino:"https://home.apoyofin.com/?page_id=510" },
        {  url_origin:"/testimonios/index.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/trabaja-con-nosotros/trabaja-con-nosotros.html",destino:"https://home.apoyofin.com/?page_id=846" },
        {  url_origin:"/unete/index.html",destino:"https://home.apoyofin.com/?page_id=846" },

        {  url_origin:"/en/como-funciona.html",destino:"https://home.apoyofin.com/?page_id=1133&lang=en" },
        {  url_origin:"/en/acerca.html",destino:"https://home.apoyofin.com/?page_id=1158&lang=en" },
        {  url_origin:"/en/trabaja-con-nosotros/Beneficios.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/clientes.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/contacto.html",destino:"https://home.apoyofin.com/?page_id=1152&lang=en" },
        {  url_origin:"/en/dudas.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/inversionistas.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/mapa-detalle.html",destino:"https://home.apoyofin.com/?page_id=1133&lang=en" },
        {  url_origin:"/en/prestamos-personales.html",destino:"https://home.apoyofin.com/?page_id=1149&lang=en" },
        {  url_origin:"/en/privacidad.html",destino:"https://home.apoyofin.com/?page_id=856" },
        {  url_origin:"/en/sucursales.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/terminos.html",destino:"https://home.apoyofin.com/?page_id=864" },
        {  url_origin:"/en/termsAppMobile.html",destino:"https://home.apoyofin.com/?page_id=1137&lang=en" },
        {  url_origin:"/en/solicita-tu-credito/index.html",destino:"https://home.apoyofin.com/?page_id=1149&lang=en" },
        {  url_origin:"/en/testimonios/index.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/trabaja-con-nosotros/index.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },
        {  url_origin:"/en/unete/index.html",destino:"https://home.apoyofin.com/?page_id=1118&lang=en" },

        {  url_origin:"/terminos-recomendados",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/trabaja-con-nosotros/recomendaciones-trabaja.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/en/apoyo-comunidad.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/testimonios/03.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/testimonios/04.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/en/trabaja-con-nosotros/trabaja-con-nosotros.html",destino:"https://home.apoyofin.com/" },
        {  url_origin:"/en/trabaja-con-nosotros/recomendaciones-trabaja.html",destino:"https://home.apoyofin.com/" },

    ]
    
    urlRedirects.map((item) => {
        if(isBrowser() && window.location.pathname===item.url_origin){
              window.location.href = item.destino;
        }    
    })
}